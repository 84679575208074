import { FC } from "react"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import config from "../../config.json"
import { MortgageComparison } from "../views/MortgageComparison"
import { SEOVariantTag } from "../views/MortgageComparison/utils/types"

export const Head: FC = () => (
  <WrappedHead
    metaTitle="Mortgage cost and repayment calculator (UK) | Habito"
    metaDescription="Hell or Habito: See what your monthly mortgage repayments could look like, and compare the total repayment cost of different mortgages. "
    canonicalUrl="https://www.habito.com/mortgage-repayment-calculator"
    noIndex={false}
    pageName="repayment_calculator"
    intercom={true}
    config={config}
  />
)

const MortgageRepaymentCalculatorPage: FC = () => (
  <WrappedBase>
    <MortgageComparison seoVariant={{ tag: SEOVariantTag.Repayment }} />
  </WrappedBase>
)

export default MortgageRepaymentCalculatorPage
